@import "./mp-colors";
//@import "src/scss/shared";
/* Ref: https://stackblitz.com/edit/angular-ngx-popper-egiyne?file=src%2Fapp%2Fapp.component.ts */

.zen-popper, .ngxp__container {
  color: $textColorDark;
  font-size: 1.2rem;
  background: white;
  border-radius: 0.5rem !important;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1) !important;
  display: none;
  z-index: 100;
  border: none !important;
  padding: 1.5rem 1.25rem !important;

  i.popper-close-icon {
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    padding: 0 0 0.5rem 0;
  }

  &.max-ht-25 {
    overflow-y: auto;
    max-height: 25rem;
  }

  &.xs {
    max-width: 16rem;
    width: -webkit-fill-available;
  }

  &.sm {
    max-width: 25rem;
    width: -webkit-fill-available;
  }

  &.min-sm {
    min-width: 25rem;
    max-width: 25rem;
    width: -webkit-fill-available;
  }

  &.md {
    max-width: 32.5rem;
    width: -webkit-fill-available;

    @media screen and (max-width: 628px) {
      max-width: 25rem;
    }
  }

  // This one wont maintain constant width adjust w.r.t the content. But still maintain max-width: 38rem
  // Used in table header info popper message text
  &.max-sm {
    max-width: 25rem;
  }

  .ngxp__arrow {
    background-color: white !important;
  }

  &.bg-transparent {
    background: rgba($textColorDark, 0.8) !important; //$textColorDark;
    color: white;
    padding: 0.5rem 1rem !important;

    .ngxp__arrow {
      background-color: transparent !important;
      color: white;
    }
  }

  /* This is used in the app-zen-table column info text popper. */
  .bg-dark-transparent {
    color: white;
    padding: 1rem;

    p {
      color: white !important;
      margin: 0;
    }
  }

}

.help-outline {
  color: $info_1;
  cursor: pointer;
  font-size: 1.25rem;
}

// mat tooltip no-wrap
.mdc-tooltip.no-wrap {
  .mdc-tooltip__surface {
    max-width: unset !important;
  }
}
