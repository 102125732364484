.mat-expansion-panel {
  --mat-expansion-container-background-color: white;
  --mat-expansion-header-hover-state-layer-color: none;
  --mat-expansion-header-focus-state-layer-color: none;
  box-shadow: none !important;
}

// To disable hover bg
.mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
  background: none !important;
}
