// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "./components/mp-colors";
@import "./components/mp-color-variants";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$ui-marketplace-v2-theme: mat.define-theme((
        color: (
                theme-type: light,
                primary: $mp-primary-color,
                tertiary: $mp-secondary-color
        )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
  --primary-color: #54E2C0;
  --secondary-color: #336AF8;

  @include mat.all-component-themes($ui-marketplace-v2-theme);
  @include mat.color-variants-backwards-compatibility($ui-marketplace-v2-theme);
}



// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($ui-marketplace-v2-theme);


@import "./components/base-font-size";
@import "./components/material-components/mat-button";
@import "./components/mp-card";
@import "./components/icons";
@import "./components/mp-popper.scss";
@import "./components/material-components/mat-form";
@import "./components/mp-popper";
@import "./components/material-components/mat-select-field";
@import "./components/material-components/mat-menu";
@import "./components/material-components/mat-dialog";
@import "./components/material-components/mat-checkbox";
@import "./components/material-components/mat-expansion-panel";
@import "./components/material-components/mat-slide-toggle";
@import "./components/material-components/mat-snackbar";
@import "./components/material-components/mat-progress-spinner";
@import "./components/material-components/mat-radio-button";
@import "./components/custom-utility-classes";
@import "./components/ngx-edittor";
