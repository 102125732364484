.mat-mdc-progress-spinner {
  &.mat-primary {
    --mdc-circular-progress-active-indicator-color: var(--primary-color);
  }

  &.mat-secondary {
    --mdc-circular-progress-active-indicator-color: var(--secondary-color);
  }

  &.mat-default {
    --mdc-circular-progress-active-indicator-color: #8597A2;
  }
}
