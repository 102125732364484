@import "./mp-colors";

.card-container {
  background: white;
  padding: 2.25rem;
  border-radius: 1rem;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.02);

  &.border {
    border: 1px solid $textColorLight_3;
  }
}
