@import "../mp-colors";

.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5rem !important;
    background-color: transparent !important;
  }

  &.success-toast .mdc-snackbar__surface {
    background: $success_2 !important;
  }

  &.warn-toast .mdc-snackbar__surface {
    background: $danger_2 !important;
  }

}
