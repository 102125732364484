@import "../mp-colors";

.mdc-dialog {
  --mdc-dialog-container-shape: 0.5rem; // border-radius of dialog

  --mdc-dialog-subhead-size: 1.6rem;
  --mdc-dialog-subhead-color: #40464D;
  --mdc-dialog-subhead-font: 'Open Sans', sans-serif;
  --mdc-dialog-subhead-weight: 100;
  --mdc-dialog-subhead-tracking: 0.01rem;
  --mat-dialog-headline-padding: 0 0 0.5rem 0;

  --mat-dialog-with-actions-content-padding: 2rem 2.5rem;
  --mat-dialog-actions-padding: 1.5rem 2rem;

  .mat-mdc-dialog-content {
    background: white;
  }

  .mat-mdc-dialog-actions {
    background: $textColorLight_4;
  }
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
  --mat-dialog-container-max-width: 90vw;
}

.mat-mdc-dialog-container {
  border-radius: 0.5rem;
  overflow: hidden; // To disable scroll
  margin: 2rem 0; // To add space above/below dialog

  .mdc-dialog__surface {
    box-shadow: none !important;
  }

  .mat-mdc-dialog-content {
    // to disable scroll inside content. Enabled scroll at cdk-overlay-pane(scroll for full dialog)
    overflow: hidden !important;
    max-height: initial !important;
  }
}

.cdk-overlay-pane {
  position: absolute !important;
  flex-direction: column;
  max-height: none;
  height: initial;
}
