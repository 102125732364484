@import "../mp-colors";

.mat-mdc-slide-toggle {
  --mat-switch-label-text-color: #40464D;
  --mat-switch-with-icon-handle-size: 1.5rem;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 0.5rem;

  // Inactive toggle design
  --mat-switch-track-outline-color: #A8B8C2 !important;

  --mdc-switch-unselected-track-color: #A8B8C2 !important;
  --mat-switch-unselected-track-outline-color: #A8B8C2 !important;

  --mdc-switch-unselected-hover-track-color: #A8B8C2 !important;
  --mat-switch-unselected-hover-track-outline-color: #A8B8C2 !important;

  --mdc-switch-unselected-focus-track-color: #A8B8C2 !important;
  --mat-switch-unselected-focus-track-outline-color: #A8B8C2 !important;

  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-unselected-hover-handle-color: white;
  --mdc-switch-unselected-focus-handle-color: white;

  // Selected
  --mdc-switch-selected-hover-handle-color: white;
  --mdc-switch-selected-focus-handle-color: white;

  .mdc-switch__icon {
    display: none;
  }

  &.medium {
    scale: 0.7;
  }
}
