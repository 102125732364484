@import "../mp-colors";

.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-color);
  --mdc-checkbox-selected-checkmark-color: #ffffff;
  --mdc-checkbox-selected-focus-icon-color: var(--primary-color);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-color);
  --mdc-checkbox-selected-icon-color: var(--primary-color);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-color);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-color);

  --mdc-checkbox-unselected-focus-state-layer-color: var(--primary-color);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--primary-color);


  --mdc-checkbox-unselected-pressed-state-layer-color: var(--primary-color);
  --mat-checkbox-label-text-color: #40464D;
  --mdc-checkbox-state-layer-size: 0;
  --mat-checkbox-label-text-size: 1rem;
  --mat-checkbox-label-text-weight: 300;

  // disabled state
  --mat-checkbox-disabled-label-color: #40464D;
  --mdc-checkbox-disabled-unselected-icon-color: #D7E0E4;

  &.mat-accent.mat-mdc-checkbox {
    --mdc-checkbox-selected-pressed-icon-color: var(--secondary-color);
    --mdc-checkbox-selected-focus-icon-color: var(--secondary-color);
    --mdc-checkbox-selected-hover-icon-color: var(--secondary-color);
    --mdc-checkbox-selected-icon-color: var(--secondary-color);
    --mdc-checkbox-selected-focus-state-layer-color: var(--secondary-color);
    --mdc-checkbox-selected-hover-state-layer-color: var(--secondary-color);
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--secondary-color);
  }

  .mdc-checkbox__native-control {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mdc-checkbox__background {
    border: 1.25px solid $textColorLight;
    border-radius: 4px;
    top: inherit;
    left: inherit;
  }

  &.mdc-checkbox--disabled .mdc-checkbox__background {
    background: $textColorLight_4;
  }

  .mdc-label {
    padding-left: 0.875rem;
  }
}
