@import "themes/mp-material-custom/components/mp-colors";

:root {
  --mat-select-panel-background-color: white;
  --mat-select-trigger-text-weight: 300;

  --mat-option-selected-state-layer-color: #EFF5F8; // textColorLight_3
  --mat-option-focus-state-layer-color: #EFF5F8; // textColorLight_3
  --mat-option-hover-state-layer-color: #EFF5F8; // textColorLight_3
  --mat-option-active-state-layer-color: #EFF5F8; // textColorLight_3

  --mat-option-label-text-weight: 300;

  --mat-menu-container-shape: 8px; // border-radius
  --mat-app-elevation-shadow-level-2: 0 10px 15px 5px rgba(0, 0, 0, 0.15);;
  --mat-select-container-elevation-shadow: 0 10px 15px 5px rgba(0, 0, 0, 0.15);;

  .mat-ripple.mat-mdc-option-ripple {
    display: none;
  }
}

.mat-mdc-option.mdc-list-item--selected {
  &:hover {
    background-color: #EFF5F8 !important;
  }
}

.mat-mdc-menu-content .mat-mdc-menu-item, .mat-mdc-option {
  min-height: 3rem !important;
}

.mat-mdc-menu-content, div.mat-mdc-select-panel {
  padding: 0 !important;
}

div.mat-mdc-select-panel {
  border-radius: 8px !important;
}

.mat-mdc-option .mat-pseudo-checkbox {
  display: none;
}

/* Hide the default arrow icon */
.mat-mdc-select-arrow svg {
  display: none; /* Hide the default MDC select arrow */
}

/* Add a custom icon */
.mat-mdc-select-arrow::after {
  content: url('../../../../../../public/images/icons/keyboard_arrow_down.svg'); /* Replace with the path to your custom icon */
  font-size: 2rem; /* Adjust the size of the icon */
  color: $textColorDark; /* Customize the color */
  position: relative;
  top: -0.625rem;
  right: 0.5rem;
}

.mat-mdc-select-disabled {
  .mat-mdc-select-arrow::after {
    opacity: 0.4;
  }
}

// To remove dropdown border used in bid-request-ready-for-pricing-step component
.mat-mdc-form-field.no-border {
  --mdc-outlined-text-field-outline-color: white;
  --mdc-outlined-text-field-hover-outline-color: white;
  --mdc-outlined-text-field-focus-outline-color: white;
  --mat-form-field-container-height: 2rem;
  --mat-form-field-container-vertical-padding: 0;

  // Disable state
  --mdc-outlined-text-field-disabled-outline-color: white;

  // Error state
  --mdc-outlined-text-field-error-outline-color: white;
  --mdc-outlined-text-field-error-hover-outline-color: white;
  --mdc-outlined-text-field-error-focus-outline-color: white;
}
