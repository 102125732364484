/* Green colors */
$success: #2BDC83; // used in RCR green tab
$success_1: #1DAC79;
$success_2: #54E2C0;
$success_3: #A9F1E0;
$success_4: #D3FFF5;
$success_5: #D1ECDE; // used in RCR green tab
$success_6: #D9FFEC;
$success_6_disabled: #eefaf8;

/* Yellow colors */
$warn_1: #FF993A;
$warn_2: #FFC061;
$warn_3: #FFE352;
$warn_4: #FFEF9B;
$warn_5: #FFFAE2;
$warn_6: #FFC0611A;

/* Blue colors */
$info_1: #68C5FA;
$info_3: #A5E8FF;
$info_4: #e0f4fe;
$pending_bg: #A7DDFA;

/* Red colors */
$danger_1: #FF7D7D;
$danger_2: #FA868D;
$danger_3: #F5A9AB;
$danger_4: #F4C8CA;
$danger_5: #FFF3f2;
$reject_bg: #FCCED0;
$negative_bg: $danger_3;
// Need static colors here because if using rgba, the transparency overlapping with other colors messes it up

/* Dark blue colors */
$blue_1: #336AF8;
$blue_2: #85A6FB;
$blue_3: #ADC3FC;
$blue_4: #D6E1FE;
$blue_5: #DEE6F8;
$blue_6: #336AF81A;

$blue_fade_1: #68C5FA;


/* Text colors */
$textColorDark: #40464D;
$textColor: #8597A2;
$textColorLight: #A8B8C2;
$textColorLight_Fade: #A8B8C233;
$textColorLight_2: #D7E0E4;
$textColorLight_3: #EFF5F8;
$textColorLight_4: #F6FAFB;
$textColorLight_5: #F0F7F9;
$textColorLight_6: #F6F8FB;


$borderColor: $textColorLight_2;

$primaryClr: #54E2C0;
$secondaryClr: #336AF8;

.text-color-dark {
  color: $textColorDark !important;
}

.text-color {
  color: $textColor !important;
}

.text-color-light {
  color: $textColorLight !important;
}

.blue-1-color {
  color: $blue_1 !important;
}

.info-1-color {
  color: $info_1 !important;
}

.text-color-white {
  color: white !important;
}

.bg-blue-1-color {
  background: $blue_1 !important;
}

.bg-blue-5-color {
  background: $blue_5 !important;
}

.bg-blue-6-color {
  background: $blue_6 !important;
}

.hover-blue-1-color:hover {
  color: $blue_1 !important;
}

.bg-hover-text-color-light-4:hover {
  background: $textColorLight_4 !important;
}

.success-1-color {
  color: $success_1 !important;
}

.danger-1-color {
  color: $danger_1 !important;
}
.warning-1-color {
  color: $warn_1 !important;
}
.warning-2-color {
  color: $warn_2 !important;
}
.bg-danger-1-color {
  background: $danger_1 !important;
}

.success-2-color {
  color: $success_2 !important;
}

.bg-success-1-color {
  background: $success_1 !important;
}

.bg-success-2-color {
  background: $success_2 !important;
}

.bg-success-6-color {
  background: $success_6;
}

.bg-text-color-light {
  background: $textColorLight !important;
}

.bg-text-color-light-2 {
  background: $textColorLight_2 !important;
}

.bg-text-color-light-3 {
  background: $textColorLight_3 !important;
}

.bg-text-color-light-4 {
  background: $textColorLight_4 !important;
}

.bg-warn-5-color {
  background: $warn_5;
}

.white-color {
  color: #fff;
}

.secondary-color {
  color: var(--secondary-color);
}

.grey-box {
  background: $textColorLight_4;
  padding: 1.5rem 2rem;
  border-radius: 2.5rem;
}

.border-text-color-light-2 {
  border: 0.1rem solid $textColorLight_2;
}

/* This status color used in follwing places
 1. app-mat-table - status column.
 2. app-procurement-snapshot timeline color.
 3. stack-ranking -> td-status-border - left border color of Annual Energy Spend column. */
@mixin zenStatusColors {

  &.no-bg {
    background: transparent !important;
    min-width: auto;
    min-height: auto;
  }

  &.box-shadow {
    box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.05);
  }

  &.default {
    background: transparent;
    color: $textColorLight_3;
  }

  &.active, &.inactive {
    background: transparent;
    font-size: 1.4rem;
    padding: 0;
  }

  &.active {
    color: $success_2;
  }

  &.inactive {
    color: var(--textColorLight45);
  }

  &.nat-gas, &.processing {
    background: $info_4;
    color: $info_1;
  }

  &.electric {
    background: $warn_5;
    color: $warn_3;
  }

  &.green, &.success-1 {
    background: $success_4;
    color: $success_1;
  }

  &.success {
    background: $success_4;
    color: $success;
  }

  &.success-2 {
    background: $success_4;
    color: $success_2;
  }

  /* Below color classes are created by MeterHelperSvc->convertStatusToCls(timelineStatus) */
  &.expired, &.rejected, &.warn, &.red {
    background: rgba($danger_1, .2);
    color: $danger_1;
    &.timeline {
      background: $danger_1;
    }
  }

  &.expiring-less-than-3-months {
    color: $danger_3;
    background-color: $danger_3;
  }

  &.expiring-greater-than-3-months {
    color: $danger_4;
    background-color: $danger_4;
  }

  &.contracted {
    color: $success_2;
    background-color: $success_2;
  }

  &.futurecontract, &.future {
    color: $success_3;
    background-color: $success_3;
  }

  &.processing {
    background-color: $info_1;
    font-size: 1.4rem;
  }

  &.onutility {
    color: $warn_2;
    background-color: $warn_2;
  }

  &.resend-urgent {
    color: $warn_1;
    background-color: $warn_1;
  }

  &.undefined {
    color: $textColorLight_2;
    background-color: $textColorLight_2;
  }

  &.booked {
    color: $success_1;
  }

  /** zen-mat-table status-indicator */
  /** NOTE: These color classes are w.r.t ZenRcStatusClass **/
  &.supplier-review, &.bidding, &.processing {
    color: $info_1;
    background-color: $info_1;
  }

  &.unknown {
    color: $textColorLight;
  }

  &.draft {
    color: $textColorLight_2;
    background: repeating-linear-gradient(
        -45deg,
        $textColorLight_2,
        $textColorLight_2 1.2rem, /* Adjust the grey stripe size */
        transparent .8rem, /* Adjust the white stripe size */
        transparent 2rem /* Total size of one stripe pattern */
    );

    // Nested selector to conditionally apply the border
    &.timeline {
      border: 1px solid $textColorLight_2;
    }
  }

  &.timed-out, &.aborted {
    color: $danger_1;
  }

  &.current {
    color: $success_2;
  }

}
