@import "../mp-colors";

.mat-mdc-radio-button {

  .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $textColorLight !important;
  }

  &.mat-accent {
    --mdc-radio-selected-focus-icon-color: var(--secondary-color) !important;
    --mdc-radio-selected-hover-icon-color: var(--secondary-color) !important;
    --mdc-radio-selected-icon-color: var(--secondary-color) !important;
    --mdc-radio-selected-pressed-icon-color: var(--secondary-color) !important;
    --mat-radio-checked-ripple-color: var(--secondary-color) !important;
  }

}
