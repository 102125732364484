@import "./mp-colors";

* {
  --ngx-editor-border-color: #D7E0E4; // $borderColor
  --ngx-editor-wrapper-border-color: #D7E0E4; // $borderColor
  --ngx-editor-seperator-color: #D7E0E4; // $borderColor
  --ngx-editor-menu-item-active-bg-color: transparent;
}

.NgxEditor__Wrapper {
  margin-bottom: 2rem;

  .NgxEditor__Content {
    padding: 1rem;
    height: 10rem;

    ul {
      margin-bottom: 0;
    }
    p {
      margin: 0 !important;
      font-size: 1rem !important;
    }
  }
}

.ngx-editor-form-field-subscript-wrapper {
  position: absolute;
  bottom: -1.5rem;
  left: 1rem;
}

.NgxEditor__Label {
  position: relative;
  top: 0.625rem;
  background: white;
  width: fit-content;
  padding: 0 0.25rem;
  color: $textColor;
  margin-left: 0.75rem;
}
