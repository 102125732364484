.border-radius-1 {
  border-radius: 1rem;
}

.min-wd-6 {
  min-width: 6rem;
}

.commodity-icon-container {
  position: relative;
  .green-icon {
    padding: 0;
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;
  }
}
