@import "./mp-colors";
// Setting base font-size as 16px for the PM
$semiBold: 500;

@mixin sharedFontSize {
  color: $textColorDark;

  h1 {
    font-weight: $semiBold;
    font-size: 1.2rem;
    color: $textColorDark;

    &.medium {
      font-weight: bold;
      font-size: 1.2rem;
    }

    &.large {
      font-weight: bolder;
      font-size: 1.6rem;
    }
  }

  h2 {
    font-weight: bold;
    font-size: 1rem;
    color: $textColorDark;

    &.large {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  h3 {
    font-weight: bolder;
    font-size: 1rem;
    color: $textColorDark;
  }

  h4 {
    font-weight: $semiBold;
    font-size: 1rem;
    color: $textColorDark;
  }

  .sub-heading-1 {
    font-weight: $semiBold;
    font-size: 0.8rem;
    color: $textColorLight;
    line-height: 1.25;
  }

  .sub-heading-2 {
    font-weight: 400;
    font-size: 0.6rem;
    color: $textColorLight;
  }

  .breadcrumb-arrow {
    display: none;
    margin-left: 0.5rem;
    color: $textColorLight;
    font-weight: bolder;
    cursor: pointer;
    @media only screen and (max-width: 1249px) {
      display: block;
    }
  }

  .with-info {
    font-weight: $semiBold;
    font-size: 1.4rem;
    color: $textColorDark;
  }

  p {
    font-size: 1.15rem;
    font-weight: 100;
    color: $textColorDark;
    margin: 0;
  }

  p, span {
    &.text-xs {
      font-size: 0.7rem !important;
    }

    &.text-sm {
      font-size: 0.85rem;
    }

    &.text-md {
      font-size: 1rem;
    }

    &.text-lg {
      font-size: 1.4rem;
    }
  }
}

body {
  h1, h2, h3 {
    margin: 0;
  }

  @include sharedFontSize;
}
