@import "../mp-colors";

.mdc-button {
  --mdc-protected-button-container-shape: 0.5rem;

  // Btn label font-size
  --mdc-text-button-label-text-size: 1rem;
  --mdc-outlined-button-label-text-size: var(--mdc-text-button-label-text-size);
  --mdc-filled-button-label-text-size: var(--mdc-text-button-label-text-size);

  //Btn padding
  --mat-text-button-horizontal-padding: 2.5rem;
  --mat-outlined-button-horizontal-padding: var(--mat-text-button-horizontal-padding);
  --mat-filled-button-horizontal-padding: var(--mat-text-button-horizontal-padding);

  // Btn height
  --mdc-text-button-container-height: 3.575rem;
  --mdc-outlined-button-container-height: var(--mdc-text-button-container-height);
  --mdc-filled-button-container-height: var(--mdc-text-button-container-height);

  // Disabled font color
  --mdc-filled-button-disabled-label-text-color: #8597A2;

  .mdc-button__label {
    display: flex;
    align-items: center;
  }

  .btn-icon {
    position: relative;
    left: -0.5rem;

    &.suffix {
      left: auto;
      right: -1.5rem;
    }
  }

  span.divider {
    border-right: 0.75px solid $textColorLight;
    margin: 0 0 0 2rem;
    height: 2.5rem;
  }

  &.mdc-button--outlined {
    background: white;
  }

  &.mat-primary.mat-mdc-button-base {
    // primary ripple colors
    --mat-text-button-ripple-color: rgba(84, 226, 192, .12);
    --mat-protected-button-ripple-color: rgba(84, 226, 192, .12);
    --mat-filled-button-ripple-color: rgba(84, 226, 192, .12);
    --mat-outlined-button-ripple-color: rgba(84, 226, 192, .12);
    --mat-icon-button-ripple-color: rgba(84, 226, 192, .12);
  }

  &.mat-accent.mat-mdc-button-base, &.mat-secondary {
    --mdc-text-button-label-text-color: var(--secondary-color);
    --mdc-protected-button-label-text-color: var(--secondary-color);
    --mdc-filled-button-container-color: var(--secondary-color);
    --mdc-outlined-button-label-text-color: var(--secondary-color);
    --mat-text-button-state-layer-color: var(--secondary-color);
    --mat-protected-button-state-layer-color: var(--secondary-color);
    --mat-outlined-button-state-layer-color: var(--secondary-color);; // hover color
    --mdc-icon-button-icon-color: var(--secondary-color);
    --mat-icon-button-state-layer-color: var(--secondary-color);

    --mdc-outlined-button-outline-color: var(--secondary-color);
    --mdc-filled-button-outline-color: var(--secondary-color);
    // accent ripple colors
    --mat-text-button-ripple-color: rgba(51, 106, 248, .12);
    --mat-protected-button-ripple-color: rgba(51, 106, 248, .12);
    --mat-filled-button-ripple-color: rgba(51, 106, 248, .12);
    --mat-outlined-button-ripple-color: rgba(51, 106, 248, .12);
    --mat-icon-button-ripple-color: rgba(51, 106, 248, .12);
  }

  &.mat-default {
    --mdc-text-button-label-text-color: #A8B8C2;
    --mat-text-button-state-layer-color: #A8B8C2; // Hover color
    --mat-outlined-button-state-layer-color:  #F6FAFB;
  }

  &.mat-warning {
    --mdc-outlined-button-label-text-color: #FF7D7D;
    --mdc-outlined-button-outline-color: #FF7D7D;
    --mat-outlined-button-state-layer-color: #FF7D7D; // Hover color
  }

  &.mat-pending {
    --mdc-outlined-button-label-text-color: #336AF8;
    --mdc-outlined-button-outline-color: #336AF8;
  }

  &.mat-success {
    --mdc-outlined-button-label-text-color: #1DAC79;
    --mdc-outlined-button-outline-color: #1DAC79;
  }

  // disable ripple
  .mat-ripple.mat-mdc-button-ripple {
    display: none;
  }
}
