//@import "https://fonts.googleapis.com/css?family=Material+Icons:wght@100;200;300;400;500;600|Material+Icons+Outlined:wght@100;200;300;400;500;600|Material+Symbols+Rounded:wght@100;200;300;400;500;600";
@import "themes/mp-material-custom/components/mp-colors";
$bodyFontFamily: 'Open Sans', sans-serif;

* {
  font-family: $bodyFontFamily;
}

h1, h2, h3, h4, h5, h6, p, label {
  font-family: $bodyFontFamily;
}

body, html {
  color: $textColorDark;
  height: 100%;
  font-size: 14px;
  letter-spacing: 0.02rem;
}

body {
  background: $textColorLight_4;
  font-family: $bodyFontFamily;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.07px;
  text-align: center;
}

.pointer {
  cursor: pointer;
}

// To support google maps api autocomplete position inside the dialogs
.pac-container {
  position: fixed !important;
}

/* .To avoid text wrap */
.no-wrap {
  white-space: nowrap;
  overflow: hidden;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.medium {
  font-size: 1.1em;
}

hr {
  border-color: $textColor !important;
}

.done-icon {
  font-size: 10rem;

  &.material-icons-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 24;
  }
}

// Used in Product Details Column
.table-renewable-icon {
  color: $success_2;
  position: absolute;
  font-size: 1.35rem;
  top: 1.5rem;
  right: -0.125rem;
  z-index: 10;
}
