@import "themes/mp-material-custom/components/mp-colors";

$scrollBarWidth: 1.4rem;

$textSize: 1rem;
$selectColWidth: 5rem;

$colMdWidth: 12rem;
$colLgWidth: 15rem;

.mat-mdc-form-field {
  // mat-label
  --mdc-outlined-text-field-label-text-color: #8597A2;
  --mdc-outlined-text-field-focus-label-text-color: #8597A2;
  --mdc-outlined-text-field-hover-label-text-color: #8597A2;

  // mat-label - on error
  --mdc-outlined-text-field-error-hover-label-text-color: #FF7D7D;
  --mdc-outlined-text-field-error-focus-label-text-color: #FF7D7D;
  --mdc-outlined-text-field-error-label-text-color: #FF7D7D;

  // On disabled
  --mdc-outlined-text-field-disabled-label-text-color: #A8B8C2; // mat-label
  --mat-select-disabled-trigger-text-color: #8597A2; // mat-select trigger
  --mdc-outlined-text-field-disabled-outline-color: #D7E0E4;

  // mat-form-field outline
  --mdc-outlined-text-field-outline-color: #D7E0E4;
  --mdc-outlined-text-field-hover-outline-color: #D7E0E4;
  --mdc-outlined-text-field-focus-outline-color: #D7E0E4;

  // mat-form-field outline - on error
  --mdc-outlined-text-field-error-outline-color: #FF7D7D; // $danger_1;
  --mdc-outlined-text-field-error-focus-outline-color: #FF7D7D; // $danger_1;
  --mdc-outlined-text-field-error-hover-outline-color: #FF7D7D; // $danger_1;

  // placeholder color
  --mdc-filled-text-field-input-text-placeholder-color: #A8B8C2;
  --mdc-outlined-text-field-input-text-placeholder-color: #A8B8C2;

  // Disabled form field bg color
  &.mat-form-field-disabled:not(.no-border) .mat-mdc-text-field-wrapper {
    background: #F6FAFB;
  }
}

.mat-mdc-form-field-error {
  --mat-form-field-error-text-color: #FF7D7D;
}

@mixin ZenCustomScrollBar {
  &::-webkit-scrollbar {
    width: $scrollBarWidth;
  }
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $textColorLight_2;
    border-radius: 1rem;
    border: 0.4rem solid white;
  }
}
